<template>
    <div class="consulting">
        <div class="container">
            <h2>Консалтинг</h2>
            <p class='link-active'>Анализ территории</p>
            <p class='link-active'>Анализ градостроительного потенциала земельных участков</p>
            <p class='link-active'>Анализ эскизов и предложений заказчика</p>
            <router-link class='link' to="/consulting1">Разработка "Дорожной карты проекта"</router-link>
            <router-link class='link' to="/consulting1">Подготовка документации на получение УРВИ и отклонений</router-link>
        </div>
        <div class="container2">
            <h2>Анализ территории</h2>
            <p>Анализ территории необходим для выявления обременений и ограничений на её использование.
                Эти исследования предшествуют разработке градостроительной документации и могут внести
                корректировку в первоначальный замысел заказчика. Анализ включает изучение правовых,
                градостроительных и планировочных ограничений, социально-экономических показателей
                прилегающих территорий, выявление дефицитов и потребностей для поступательного развития
                территории. Исследования правовой базы и нормативной документации, позволяют детально
                четко сформулировать санитарно-защитные, экологические, культурно-исторические
                ограничения, определить четкие параметры обязательных планировочных элементов застройки.</p>
            <h2>Анализ градостроительного потенциала земельных участков</h2>
            <p>Для определения цели приобретения или использования участка, мы готовы выполнить
                предварительный анализ по следующим документам:</p>
            <ul>
                <li>выписке ЕГРН, которая содержит общедоступные сведения, которые могут быть получены
                    любым лицом за короткие сроки. В случае нахождения на участке объектов недвижимости
                    анализируется выписка ЕГРН для объекта недвижимости. В выписках ЕГРН присутствует
                    имеющая существенное значение информация: данные регистрации и учета участка, о назначении
                    по виду использования, информация о виде права и документах правообладателя,
                    об ограничениях, аресте, залоге. Площадные данные, характеристики, координаты.</li>
                <li>договорам аренды, заключенных заказчикам, где в рамках договора, определены права и
                    ограничения заказчика (использование, сроки, специальные требования и условия, возможности
                    сдачи в субаренду и выкупа участка).</li>
                <li>утвержденной градостроительной документации в отношении участка по электронной базе
                    Комитета по градостроительству и архитектуре (КГА), позволяет получить информацию о
                    разработке проекта планировки и проекта межевания территории (ППТ и ПМ), в состав
                    которой включен участок заказчика.</li>
                <li>Анализ Генерального плана Санкт-Петербурга и Правил землепользования и застройки
                    (ПЗЗ) позволяет аккумулировать информацию о функциональном и территориальном
                    зонировании, градостроительных регламентах, градопланировочных ограничениях,
                    санитарно-защитных зонах.</li>
            </ul>
            <p>Полученная информация, дает нам понимание о возможности застройки участка, необходимости
                в дальнейшем разрабатывать, или нет, документацию или вносить изменения в уже утвержденный
                ППТ и ПМ, готовить ли документацию по оценке архитектурно-градостроительного потенциала
                территории, требуется ли получать разрешение на условно разрешенный
                вид использования (УРВИ) участка.</p>
            <p>Систематизируя данные, мы подготовим профессиональное заключение с выводами о возможности
                градостроительного развития земельного участка.</p>

            <h2>Анализ эскизов и предложений заказчика</h2>
            <p>Наши специалисты готовы выполнить анализ уже разработанной заказчиком градостроительной
                документации – эскизов застройки, инициативных градостроительных предложений,
                предварительных концепций. Мы определим сильные и слабые стороны идеи заказчика.
                Наше заключение будет содержать:</p>
            <ul>
                <li>информацию о реалистичности реализации планов заказчика;</li>
                <li>перечень ограничений и возможных обременений;</li>
                <li>план необходимых для реализации идеи заказчика мероприятий;</li>
                <li>перечень необходимых проектных работ;</li>
                <li>предварительный (базовый) укрупненный план мероприятий и работ.</li>
            </ul>
        </div>
    </div>
</template>
<script>
</script>
<style scoped>
.consulting {
    background-image: url(../assets/consulting_background.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 100%;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    padding: 50px;
}

h2 {
    margin-top: 0;
}

p,
ul {
    font-size: 12px;
}

.link {
    font-size: 11px;
    text-decoration: none;
    color: black;
    display: block;
}

.link-active {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0;
}

.container2 {
    width: 55%;
    box-shadow: -10px 10px 10px;
    padding: 20px;

}

@media screen and (max-width: 550px) {

    .consulting {
        background-image: none;
        flex-direction: column;
    }

    .container2 {
        width: 80%;
        margin: 20px auto;
        box-shadow: none;
    }

    .container {
        width: 80%;
        margin: 0 auto;
        padding: 20px auto 20px;
        box-sizing: border-box;
    }
}</style>