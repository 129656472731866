<template>
    <div class="economic">
        <div class="container1">
            <h3>ЭКОНОМИЧЕСКАЯ ЭФФЕКТИВНОСТЬ ПРОЕКТА </h3>
            <p class="menu">Алгоритм расчета экономической эффективности проекта</p>
            <p class="menu">Анализ рынка недвижимости</p>
            <p class="menu">Расчет капитальных затрат</p>
            <p class="menu">Обоснование финансового дохода</p>
            <p class="menu">Разработка финансовой модели проекта</p>
        </div>
        <div class="container2">
            <h3>Алгоритм расчета экономической эффективности проекта</h3>
            <p class="main-text">Для расчета показателей эффективности выполняются:</p>
            <ul class="main-text-list">
                <li>анализ рынка недвижимости в выбранном сегменте с рекомендациями по застройке;</li>
                <li>расчет капитальных затрат (CAPEX);</li>
                <li>обоснование финансового дохода проекта (вариантность получения прибыли);</li>
                <li>разработка финансовой модели инвестиционного проекта.</li>
            </ul>
            <p class="main-text">Строительство новых объектов, редевелопмент промышленных, или неиспользуемых
                территорий, реновация застройки - требуют детального расчета и
                планирования бизнес процессов. Эффективность инвестиционного проекта
                определяется соотношением технических и экономических характеристик
                объектов, их функционального назначения, сроков реализации проекта,
                планирования финансовых потоков, пониманием возможных рисков и
                перспектив развития рынка недвижимости в выбранном сегменте.</p>
            <h3>Анализ рынка недвижимости</h3>
            <p class="main-text">Систематизированные выводы и рекомендации по результатам исследований и анализа рынка
                недвижимости, помогут определить и спрогнозировать детальные характеристики
                конкурентоспособных объектов недвижимости. Помогут определить функциональное назначение
                и зонирование, возможные риски инвестора, позволят спрогнозировать стоимостные границы
                финансового дохода инвестиционного проекта, спрогнозировать время начала эксплуатации,
                старта продаж, или сдачи в аренду построенных объектов. </p>
            <h3>Расчет капитальных затрат</h3>
            <p class="main-text">Одновременно с анализом рынка недвижимости, мы выполняем расчет затратной составляющей
                инвестиционного проекта. Расчет экономической эффективности проекта – следующий за
                разработкой градостроительного раздела концепции, этап реализации инвестиционного
                проекта строительства. </p>
            <p class="main-text">Параметры застройки территории, заложенные в концепции, позволяют выполнить расчет
                капитальных затрат в строительство (CAPEX) и определить необходимый размер вложений
                и затрат на строительство первичных и вторичных объектов строительства
                (здания, квартиры, офиса, коммерческого помещения). В этом разделе разрабатывается
                совмещенный график с распределением всех работ до ввода объекта в эксплуатацию по
                периодам и этапам, с распределением всех необходимых затрат.</p>
        </div>
        <div class="container3">
            <h3>Обоснование финансового дохода</h3>
            <p class="main-text">Финансовый доход инвестора формируется исходя из результатов анализа ранка недвижимости,
                соотношения объемов собственных и привлеченных инвестиций, сроков строительства и
                предпочтений инвестора – выбора варианта реализации первичных и вторичных объектов
                недвижимости.</p>
            <ul class="main-text-list">
                <li>продажа инвестиционного проекта, когда «упакованный» проектной и разрешительной
                    документацией инвестиционный проект продается до начала строительства;</li>
                <li>реализация вторичных объектов строительства (квартиры, офисы, коммерческие
                    помещения) по схеме долевого участия в строительстве;</li>
                <li>продажа вторичных объектов строительства после ввода объекта в эксплуатацию;</li>
                <li>оформление права собственности на объекты и сдача в аренду вторичных объектов
                    строительства, эксплуатация силами собственной управляющей компании;</li>

                <li>оформление права собственности на объекты и эксплуатация объектов строительства,
                    получение дохода от расширения основной коммерческой
                    деятельности предприятия - владельца. </li>
            </ul>
            <p class="main-text">Для выбранного варианта получения финансового дохода в данном разделе мы составляем
                график доходной составляющей с распределением планируемых сумм дохода.
                В последующих расчетах - готовим итоговый совмещенный график затратной и
                доходной составляющих проекта. </p>
            <h3>Разработка финансовой модели проекта</h3>
            <p class="main-text">Для расчета и оценки экономической эффективности инвестиций нашими специалистами
                разрабатывается финансовая модель проекта. Это идеальный рабочий инструмент для
                управления финансовыми потоками, долгосрочного планирования бизнеса, позволяющий
                не только контролировать финансовые потоки и инвестиции, но и при необходимости
                – оперативно реагировать на любые обстоятельства.</p>
            <p class="main-text">Финансовая модель проекта – это прогноз и план реализации идеи, используемые для оценки
                эффективности и привлекательности проекта, его планирования и контроля реализации,
                позволяет структурировать расчет критериев оценки, ускорять работу и снижать
                вероятность ошибок.</p>
            <p class="main-text">Финансовая модель удобна для формирования плана доходов и расходов по проекту,
                при постановке задач и контроле реализации. Инвариантно и оперативно решать
                эти задачи становится возможным, сопоставляя в финансовой модели различные
                бюджеты - капитальные затраты, доходы, операционный бюджет, инвестиции в
                оборотный капитал, налоговые и социальные отчисления, привлечения и погашения
                внешнего финансирования. </p>
        </div>

    </div>
</template>
<script>
</script>
<style scoped>
.economic {
    background-image: url(../assets/economy_background.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    margin: 0;
    justify-content: space-around;
    padding: 20px;
    height: auto;
}

h3 {
    margin-top: 15px;
    font-size: 12px;
}

.container1 {
    width: 30%;
}
.container2 {
    width: 30%;
}
.container3 {
    width: 30%;
}
.menu{
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 11px;
}
.main-text {
    font-size: 11px;
    text-align: justify;
    text-indent: 20px;
}
.main-text-list {
    font-size: 11px;
    text-align: justify;
    margin-left: 10px;
}
@media  screen and (min-width: 1500px) {
    .economic {
        height: 100vh;
    }
    .container1 {
    width: 25%;
    padding-top: 50px;
}
.container2 {
    width: 25%;
    padding-top: 50px;
}
.container3 {
    width: 25%;
    padding-top: 50px;
}
}
@media  screen and (max-width: 750px) {
    .economic {
        background-size: cover;  
        background-position: right;
        flex-wrap: wrap;
    }
    .container1 {
    width: 80%;
    font-size: 14px;
    
}
.container2 {
    width: 40%;
    font-size: 14px;

}
.container3 {
    width: 40%;
    font-size: 14px;

}
}
@media  screen and (max-width: 500px) {
    .economic {
        
        flex-direction: column;
    }
    .container1 {
    width: 80%;
    margin: 0 auto;
}
.container2 {
    width: 80%;
    margin: 0 auto;
}
.container3 {
    width: 80%;
    margin: 0 auto;
}
}
</style>