<template>
  <div class="about">
    <div class="main_container">
      <div class="container1">
        <h1>О НАШЕЙ КОМАНДЕ</h1>
        <p>Общество с ограниченной ответственностью «АГ Портал» (ООО «АГ Портал»)
          - молодая, быстро развивающаяся
          компания комплексного проектирования и управления строительными проектами. </p>
        <p>Созданная в 2019 году опытными специалистами в области градостроительного
          проектирования и реализации крупных инвестиционных проектов компания,
          осуществляет работы в сфере градостроительного анализа и обоснования
          проектов комплексного освоения территорий, проектов строительства гражданских
          и промышленных объектов.</p>
        <p>Мы разрабатываем проекты планировки и межевания территорий, застройки крупных
          земельных участков, объектов капитального строительства, осуществляем инжиниринговую
          деятельность при подготовке и реализации инвестиционных строительных проектов
          любой сложности. </p>
        <p>Процесс разработки проектной документации в современных условиях – это
          в том числе выработка бизнес стратегии реализации проекта, расчет экономической
          эффективности, плановое управление проектом. Такой комплексный подход
          обеспечивает заказчику полный и непрерывный цикл последовательных
          взаимосвязанных этапов. </p>
        <p>Профессиональный опыт наших специалистов позволяет сопровождать заказчика на всех
          стадиях реализации проекта, от зарождения идеи до разрешения на строительство, успешно
          реализовать проект «под ключ».</p>
      </div>
      <div class="container2">
        <p>Наша миссия – максимальный комфорт заказчика, его понимание всех жизненных циклов
          инвестиционного строительного проекта. В компетенциях специалистов
          ООО «АГ Портал» - реализация «дорожной карты» проекта с учетом всех индивидуальных
          нюансов и особенностей территории и возможностей заказчика. Выбор оптимального,
          экономически эффективного пути реализации проекта строительства
          любой сложности, любого назначения.</p>
        <p>Генеральный директор Жуйков В.Н.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
p {
  color: white;
  font-size: 12px;
}

.about {
  margin: 0 auto;
  background-image: url(../assets/title_page_background1.jpg);
  background-size: cover;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-position: left;
}

h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 12px;
  color: white;
}

.main_container {
  display: flex;
  width: 65vw;
  justify-content: space-around;
}

.container1 {
  width: 40%;
}

.container2 {
  width: 40%;
  margin-top: auto;
}
@media screen and (max-width: 900px){
  .main_container {
  width: 80vw;
}
}
@media screen and (max-width: 690px){
  .main_container {
  width: 90vw;
}
}
@media screen and (max-width: 550px){
  .main_container {
  flex-direction: column;
  width: 100%;
  
}
.container1 {
  width: 80%;
  text-align: justify;
  font-size: 10px;
  margin: 0 auto;
}

.container2 {
  width: 80%;
  text-align: justify;
  font-size: 10px;
  margin: 0 auto;
}
}
</style>
