<template>
    <div class="consulting1">
        <div class="container">
            <h2>Разработка «Дорожной карты» проекта.</h2>
            <p>Разработке концепции, проектной документации, расчету экономической эффективности
                проекта предшествует разработка «Дорожной карты» проекта. Дорожная карта
                проекта (roadmap) — документ, в котором перечислены цели проекта, его ключевые этапы,
                контрольные даты и ответственные исполнители. На основе карты формируются
                подробные планы и графики выполнения отдельных видов работ, этапов проектирования,
                согласования, строительства. Дорожная карта позволяет увидеть взаимосвязь всех участников
                проекта – исполнителей, подрядчиков, субподрядчиков, заказчика. Это позволяет увидеть
                полную картину жизненных циклов проекта и осуществлять контроль достижения поставленных
                целей на конкретные плановые даты. В частности, в «Дорожной карте» закладываются
                плановые сроки детального совмещенного графика капитальных затрат по всем позициям и
                финансового дохода инвестиционного проекта, необходимого для расчета экономической
                эффективности.</p>
            <h2>Подготовка документации на получение УРВИ и отклонений.</h2>
            <p>УРВИ - условно разрешенный вид использования земельного участка. Выбор вида застройки
                земельного участка, должен начинаться с анализа основных законов территориального
                планирования - Генерального плана Санкт-Петербурга и Правил землепользования и
                застройки Санкт-Петербурга (ПЗЗ). Оба документа определяют градостроительный регламент
                и виды разрешенного использования земельных участков. Регламент также определяет
                перечень условно разрешенных видов использования. Это позволяет застройщику, выбрать
                соответствующий желанию и отсутствующий в регламенте, нужный вид использования.</p>
            <p>Разрешение на УРВИ предоставляется исполнительными органами государственной власти.
                Процедура регламентирована нормой закона - ст.39 ГрК РФ.</p>
            <p>В данной ситуации, мы готовы оказать консультационные услуги заказчику, подготовить
                необходимый пакет документов с обосновывающими материалами для подачи застройщиком
                предложений в Комиссию по землепользованию и застройке Санкт-Петербурга.</p>
        </div>

        <div class="container1">
            <h2>КОНСАЛТИНГ</h2>
            <router-link class='link' to="/consulting">
                
                    <p class='link'>Анализ территории</p>
                    <p class='link'>Анализ градостроительного потенциала земельных участков</p>
                    <p class='link'>Анализ эскизов и предложений заказчика</p>
                
            </router-link>

            <p class='link-active'>Разработка "Дорожной карты проекта"</p>
            <p class='link-active'>Подготовка документации на получение УРВИ и отклонений</p>
        </div>
    </div>
</template>
<script>
</script>
<style scoped>
.consulting1 {
    background-image: url(../assets/consulting1_background.jpg);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    min-height: 80vh;
    display: flex;

}

.container {
    width: 50%;
    padding: 50px;
    margin-left: 20px;
}

h2,
p {
    font-size: 12px;
    text-align: justify;
}
.link {
    font-size: 12px;
    text-decoration: none;
    color: rgb(55, 71, 79);
    display: block;
    margin-top: 0;
    margin-bottom: 0;
}
.link-active {
    font-size: 12px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0;
}
.container1 {
    padding-top: 40px;
}
@media  screen and (max-width: 600px) {
    .consulting1 {
    background-image :none;
    flex-direction: column-reverse;
}
.container1 {
    padding-left: 25px;
    padding-right: 25px;
}
.container {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 0;
    box-sizing: border-box;
}
}
</style>