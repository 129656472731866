<template>
    <div class="implementation">
        <div class="main_container">
            <h2>Управление проектом</h2>
            <p>ООО «АГ Портал» от имени заказчика / застройщика осуществляет управление проектом,
                правовое сопровождение, от формирования идеи проекта и выбора земельного участка
                до ввода объекта капитального строительства в эксплуатацию. Мы готовим задания
                на проектирование, заключаем договоры и контролируем проектный бюджет, весь
                рабочий процесс, сроки исполнения и качество подготовки документации, получаем
                необходимые согласования, заключения, технические условия. Выполняем функции
                технического заказчика.</p>
            <h2>Функции технического заказчика</h2>
            <p>ООО «АГ Портал», на стадии разработки проектной документации застройки и
                объектного проектирования, как технический заказчик – участник инвестиционного
                строительного проекта, осуществляет организацию, планирование и управление
                проектированием и строительством, от имени заказчика / застройщика, заключает
                договоры о выполнении инженерных изысканий, подготовке проектной документации,
                строительстве, реконструкции, капитальном ремонте, сносе объектов капитального
                строительства. В роли технического заказчика, мы формируем задания на выполнение
                работ, предоставляем участникам инвестиционного проекта материалы и документы,
                необходимые для выполнения работ.</p>
            <p>Основные цели в работе технического заказчика – успешная реализация проекта,
                снижение строительных рисков, соблюдение сроков, стоимости и качества
                строительства, контроль бюджета, достижение запланированных технико-экономических
                и эксплуатационных показателей.</p>
            <p>Деятельность технического заказчика направлена на своевременное и надлежащее
                выполнение всеми участниками проекта принятых обязательств. </p>
        </div>
        <div class="links_container">
            <h3>Реализация</h3>
            <p>Управление проектом</p>
            <p>Функция технического заказчика</p>
        </div>
    </div>
</template>
<script>
</script>
<style scoped>
.implementation {
    background-image: url(../assets/implementation_background.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    min-height: 90vh;
}
.main_container {
    padding-top: 30px;
    width: 40vw;
    padding-left: 20px;
    box-shadow: -10px 10px 10px;
    margin-left: 30px;
}
p {
    font-size: 11px;
    text-align: justify;
    text-indent: 20px;
}
h3 {
    font-size: 12px;
    margin-left: 20px;
}
h2 {
    font-size: 12px;
}
.links_container {
    width: 40%;
}
@media screen and (max-width: 600px)  {
    .implementation {
    background-image: none;
    flex-direction: column-reverse;
   
}
.links_container {
    width: 80%;
    margin: 0 auto 50px;
}
.main_container {
    width: 90%;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-left: 0;
}
}
</style>